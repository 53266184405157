import { use, init } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enCommonNamespace from '../../public/locales/en/common.json';
import enCompletePageSettingNamespace from '../../public/locales/en/complete-page-setting.json';
import enCustomDomainNamespace from '../../public/locales/en/custom-domain.json';
import enDeliverySettingsNameSpace from '../../public/locales/en/delivery-settings.json';
import enEGiftOnlyNamespace from '../../public/locales/en/e-gift-only.json';
import enGiftDataNamespace from '../../public/locales/en/gift-data.json';
import enGitUrlSettingNamespace from '../../public/locales/en/gift-url-setting.json';
import enGiftWrappingNamespace from '../../public/locales/en/gift-wrapping.json';
import enInitialChecklistNamespace from '../../public/locales/en/initial-checklist.json';
import enMarketingNamespace from '../../public/locales/en/marketing.json';
import enMessageCardNamespace from '../../public/locales/en/message-card.json';
import enMultipleShippingAddressNamespace from '../../public/locales/en/multiple-shipping-address.json';
import enPlansNamespace from '../../public/locales/en/plans.json';
import enProductsNamespace from '../../public/locales/en/products.json';
import enSelectableGiftsNamespace from '../../public/locales/en/selectable-gifts.json';
import enShipmentEmailNamespace from '../../public/locales/en/shipment-email.json';
import enShippingRateNamespace from '../../public/locales/en/shipping-rate.json';
import enTopNamespace from '../../public/locales/en/top.json';
import enUrlNamespace from '../../public/locales/en/url.json';
import jaCommonNamespace from '../../public/locales/ja/common.json';
import jaCompletePageSettingNamespace from '../../public/locales/ja/complete-page-setting.json';
import jaCustomDomainNamespace from '../../public/locales/ja/custom-domain.json';
import jaDeliverySettingsNameSpace from '../../public/locales/ja/delivery-settings.json';
import jaEGiftOnlyNamespace from '../../public/locales/ja/e-gift-only.json';
import jaGiftDataNamespace from '../../public/locales/ja/gift-data.json';
import jaGitUrlSettingNamespace from '../../public/locales/ja/gift-url-setting.json';
import jaGiftWrappingNamespace from '../../public/locales/ja/gift-wrapping.json';
import jaInitialChecklistNamespace from '../../public/locales/ja/initial-checklist.json';
import jaMarketingNamespace from '../../public/locales/ja/marketing.json';
import jaMessageCardNamespace from '../../public/locales/ja/message-card.json';
import jaMultipleShippingAddressNamespace from '../../public/locales/ja/multiple-shipping-address.json';
import jaPlansNamespace from '../../public/locales/ja/plans.json';
import jaProductsNamespace from '../../public/locales/ja/products.json';
import jaSelectableGiftsNamespace from '../../public/locales/ja/selectable-gifts.json';
import jaShipmentEmailNamespace from '../../public/locales/ja/shipment-email.json';
import jaShippingRateNamespace from '../../public/locales/ja/shipping-rate.json';
import jaTopNamespace from '../../public/locales/ja/top.json';
import jaUrlNamespace from '../../public/locales/ja/url.json';
if ("object" !== 'undefined') {
    use(new LanguageDetector(null, {
        // localStorageのi18nextLngよりも、ブラウザの設定言語を優先する
        order: [
            'querystring',
            'cookie',
            'navigator',
            'localStorage',
            'htmlTag'
        ],
        htmlTag: document.documentElement
    }));
}
use(initReactI18next);
init({
    fallbackLng: 'ja',
    supportedLngs: [
        'en',
        'ja'
    ],
    ns: [
        'common',
        'top',
        'products',
        'e-gift-only',
        'initial-checklist',
        'shipping-rate',
        'marketing',
        'message-card',
        'multiple-shipping-address',
        'gift-wrapping',
        'selectable-gifts',
        'gift-url-setting',
        'complete-page-setting',
        'url', 
    ],
    load: 'languageOnly',
    interpolation: {
        escapeValue: false
    },
    react: {
        useSuspense: false
    },
    resources: {
        en: {
            common: enCommonNamespace,
            top: enTopNamespace,
            products: enProductsNamespace,
            plans: enPlansNamespace,
            marketing: enMarketingNamespace,
            url: enUrlNamespace,
            'complete-page-setting': enCompletePageSettingNamespace,
            'initial-checklist': enInitialChecklistNamespace,
            'shipping-rate': enShippingRateNamespace,
            'message-card': enMessageCardNamespace,
            'multiple-shipping-address': enMultipleShippingAddressNamespace,
            'gift-data': enGiftDataNamespace,
            'gift-wrapping': enGiftWrappingNamespace,
            'selectable-gifts': enSelectableGiftsNamespace,
            'custom-domain': enCustomDomainNamespace,
            'shipment-email': enShipmentEmailNamespace,
            'e-gift-only': enEGiftOnlyNamespace,
            'gift-url-setting': enGitUrlSettingNamespace,
            'delivery-settings': enDeliverySettingsNameSpace
        },
        ja: {
            common: jaCommonNamespace,
            top: jaTopNamespace,
            products: jaProductsNamespace,
            plans: jaPlansNamespace,
            marketing: jaMarketingNamespace,
            url: jaUrlNamespace,
            'complete-page-setting': jaCompletePageSettingNamespace,
            'initial-checklist': jaInitialChecklistNamespace,
            'shipping-rate': jaShippingRateNamespace,
            'message-card': jaMessageCardNamespace,
            'multiple-shipping-address': jaMultipleShippingAddressNamespace,
            'gift-data': jaGiftDataNamespace,
            'gift-wrapping': jaGiftWrappingNamespace,
            'selectable-gifts': jaSelectableGiftsNamespace,
            'custom-domain': jaCustomDomainNamespace,
            'shipment-email': jaShipmentEmailNamespace,
            'e-gift-only': jaEGiftOnlyNamespace,
            'gift-url-setting': jaGitUrlSettingNamespace,
            'delivery-settings': jaDeliverySettingsNameSpace
        }
    }
});
